import { userStore } from 'stores';
import { generateUUID } from 'utils';

const getXSRFCookie = () => document.cookie
  .split('; ')
  .find((row) => row.startsWith('XSRF-TOKEN=')).split('=')[1];

const overrideHeaders = (headers, method) => (method === 'GET' ? headers : {
  ...headers,
  'X-XSRF-TOKEN': getXSRFCookie()
});

export const getDefaultHeaders = (method = 'GET') => {
  const headers = {
    'Content-Type': 'application/json'
  };

  switch (method.toLowerCase()) {
    case 'post':
      headers['X-XSRF-TOKEN'] = getXSRFCookie();
      break;
    case 'put':
      headers['Content-Type'] = 'application/json';
      headers['X-XSRF-TOKEN'] = getXSRFCookie();
      break;
    case 'delete':
      headers['X-XSRF-TOKEN'] = getXSRFCookie();
      break;
    case 'patch':
      headers['X-XSRF-TOKEN'] = getXSRFCookie();
      break;
    default:
      break;
  }

  return headers;
};

export const httpSettings = (settings) => {
  const headers = settings.headers ? overrideHeaders(settings.headers, settings.method) : getDefaultHeaders(settings.method);
  const init = settings;

  if (init.body) {
    switch (headers['Content-Type']) {
      case 'application/json':
        if (!(init.body instanceof FormData)) {
          init.body = JSON.stringify(init.body);
        }
        break;
      case 'application/x-www-form-urlencoded': {
        const formDataEntries = init.body instanceof FormData ? init.body.entries() : Object.entries(init.body);
        const formDataArray = [];

        formDataEntries.forEach(([key, value]) => {
          formDataArray.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        });
        init.body = formDataArray.join('&');
        break;
      }
      default:
        break;
    }
  }

  const userToken = userStore.getToken();

  headers.Accept = '*/*';
  headers['X-Request-ID'] = headers['X-Request-ID'] || generateUUID();

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  init.headers = headers;

  return init;
};