import i18n from 'i18next';
import { userStore } from 'stores';
import { translate } from 'utils/translation';
import { httpSettings } from './request';

const getErrorTranslation = (code, message) => {
  if (i18n.exists(`errors.${message}`)) {
    return translate(`errors.${message}`);
  }

  return `${translate('errors.UNCATCHED_ERROR')} (${translate('common.error')} ${code})`;
};
export class HTTPError extends Error {
  constructor(code, message) {
    super(getErrorTranslation(code, message));
    this.statusCode = code;
  }
}

const defaultResponseHandler = async (response) => {
  const { status, statusText, headers } = response;

  switch (status) {
    case 200:
      return response.json();
    case 201: {
      const location = headers.get('location');
      if (location) {
        const locationSplitted = location.split('/');
        return locationSplitted[locationSplitted.length - 1];
      }

      return true;
    }
    case 204:
    case 202:
      return true;
    case 401:
      if (userStore.isConnected) {
        window.location.href = window.location.origin;
        return false;
      }

      return userStore.keycloak && userStore.keycloak.login();
    case 412:
      return response.json().then((error) => {
        throw error;
      });
    default:
      throw new HTTPError(status, statusText);
  }
};

const GET = (url, headers) => fetch(url, httpSettings({
  headers,
  method: 'GET',
  credentials: 'include'
})).then(defaultResponseHandler);

const POST = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'POST',
  headers,
  credentials: 'include',
  body
})).then(defaultResponseHandler);

const PUT = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PUT',
  headers,
  credentials: 'include',
  body
})).then(defaultResponseHandler);

const PATCH = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'PATCH',
  credentials: 'include',
  headers,
  body
})).then(defaultResponseHandler);

const DELETE = (url, body = {}, headers = null) => fetch(url, httpSettings({
  method: 'DELETE',
  credentials: 'include',
  headers,
  body
})).then(defaultResponseHandler);

// Regroup all functions inside a single const to simplify
export const RequestHelper = {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE
};